// Core Packages
import { useState } from 'react';

// Custom Components
import SearchField from '@/components/SearchField';
import { CrossIcon, SearchIcon } from '@/assets/icons/shared';

// Utils
import { useTranslation } from 'react-i18next';

// Styles
import styles from '../style.module.css';

function ExpandableSearch({
  heading,
  placeholder = 'Search by Brand',
  setSearchKey,
  setShowMore = () => {},
}) {
  const { t: translate } = useTranslation();
  const [isTextFieldExpanded, setIsTextFieldExpanded] = useState(false);

  const handleToggleExpandTextField = () => {
    setSearchKey('');
    setIsTextFieldExpanded(!isTextFieldExpanded);
    setShowMore(false);
  };

  const handleTextInputChange = (value) => {
    setSearchKey(value);
  };

  return (
    <div className="row a-center j-space-between">
      {isTextFieldExpanded && (
        <>
          <div
            className={`row j-space-between ${styles.search_field_container}`}
          >
            <SearchField
              useDebouncing={false}
              placeholder={translate(placeholder)}
              onChange={handleTextInputChange}
              className={styles.searchField}
              setShowMore={setShowMore}
            />
          </div>
          <CrossIcon
            className={styles.crossIcon}
            onClick={handleToggleExpandTextField}
          />
        </>
      )}

      {!isTextFieldExpanded && (
        <>
          <h4 className={styles.filterTitle}>{heading}</h4>
          <div aria-hidden="true" onClick={handleToggleExpandTextField}>
            <SearchIcon className={styles.searchIcon} />
          </div>
        </>
      )}
    </div>
  );
}

export default ExpandableSearch;
