import React, { useState, useEffect, useRef } from 'react';
// Core Packages
import classnames from 'classnames';

// Material
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import Select from '@material-ui/core/Select';
import { SearchIcon } from '@/assets/icons/register';

// Custom Components
import StyledLabel from '../Label';

// Styles
import styles from './style.module.css';

export default function StyledSelect({
  className,
  label,
  placeholder = '',
  value = '',
  required,
  options = [],
  disabled = false,
  getLabel,
  getValue,
  onChange,
  searchable,
  searchPlaceholder,
}) {
  const inputFocus = useRef(null);
  const [searchTxt, setSearchTxt] = useState('');
  const selectClasses = classnames(styles.select, className);
  const changeHandler = (option) => () => onChange(option);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const handleFilterOpts = () => {
    const newOpts = options.filter((o) => `${getLabel(o)}`.toLowerCase().includes(`${searchTxt}`.toLowerCase()));
    setFilteredOptions(newOpts);
  };

  const focusInput = () => {
    setTimeout(() => {
      inputFocus.current.focus();
    });
  };

  useEffect(() => {
    if (!searchable) return;

    handleFilterOpts();
    if (!searchTxt) return;
    focusInput();
  }, [searchTxt]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const hidePlaceholder = () => setShowPlaceholder(false);
  const revealPlaceholder = () => setShowPlaceholder(true);

  return (
    <>
      {label && (
        <StyledLabel label={label} required={required} htmlFor="select" />
      )}
      <Select
        data-test="component-styled-select"
        variant="outlined"
        className={selectClasses}
        labelId={label}
        id={label}
        required={required}
        placeholder={placeholder}
        value={value || placeholder}
        disabled={disabled}
        onOpen={hidePlaceholder}
        onClose={() => {
          setTimeout(() => {
            setFilteredOptions(options);
            setSearchTxt('');
            revealPlaceholder();
          });
        }}
        MenuProps={{
          disablePortal: true,
          MenuListProps: {},
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {searchable ? (
          <MenuItem
            key="searchFieldMenu"
            onKeyDown={(e) => {
              e.stopPropagation();

              // if (e.code === "Backspace") {
              //   if (!searchTxt.length) return;
              //   const newTxt = searchTxt.substring(0, searchTxt.length - 1);
              //   setSearchTxt(newTxt);
              // }

              // if (e.code === "Space") {
              //   setSearchTxt(`${searchTxt} `);
              // }

              // if (!e.code.includes("Key")) return;
              // const txt = e.code.replace("Key", "").toLowerCase();
              // setSearchTxt(`${searchTxt}${txt}`);
            }}
            autoFocus
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className={styles.searchContainer} key="searchFieldContainer">
              <TextField
                onClick={(event) => {
                  event.stopPropagation();
                }}
                autoFocus
                ref={inputFocus}
                key="searchField"
                value={searchTxt}
                onChange={(e) => setSearchTxt(e.target.value)}
                variant="outlined"
                placeholder={searchPlaceholder || ''}
                className={styles.searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </MenuItem>
        ) : null}
        {placeholder && showPlaceholder ? (
          <MenuItem
            key={placeholder}
            value={placeholder}
            onClick={() => {}}
            disabled
          >
            {placeholder}
          </MenuItem>
        ) : null}
        {filteredOptions.map((option, index) => (
          <MenuItem
            onKeyDown={(e) => {
              if (searchable) {
                e.stopPropagation();
                focusInput();
              }
            }}
            key={index}
            value={getValue(option)}
            onClick={changeHandler(option)}
          >
            {getLabel(option)}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
