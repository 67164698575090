/* eslint-disable no-nested-ternary */
import Head from 'next/head';

const PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL;
const isSK = PUBLIC_API_URL.includes('skexpress');

export default function SEOTags({
  title = isSK ? 'SK Express' : 'Alerzoshop',
  siteName = isSK ? 'SK Express' : 'Alerzoshop',
  description = isSK ? 'SK Express' : 'Alerzoshop',
  keywords = 'AlerzoShop',
  imageUrl = isSK
    ? '/icons/sk-android-chrome-512x512.png'
    : '/icons/android-chrome-512x512.png',
  websiteUrl = typeof window !== 'undefined'
    ? window.location.href
    : isSK
      ? 'https://www.skexpress.com'
      : 'https://www.alerzo.com',
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={websiteUrl} />
      <meta property="og:site_name" content={siteName} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />

      <link
        rel="shortcut icon"
        href={isSK ? '/icons/sk-favicon.ico' : '/icons/favicon.ico'}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={
          isSK
            ? '/icons/sk-apple-touch-icon.png'
            : '/icons/apple-touch-icon.png'
        }
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={isSK ? '/icons/sk-favicon-32x32.png' : '/icons/favicon-32x32.png'}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={isSK ? '/icons/sk-favicon-16x16.png' : '/icons/favicon-16x16.png'}
      />
    </Head>
  );
}
