// Styles
import { useTranslation } from 'react-i18next';

import styles from '../style.module.css';

function ShowMoreLess({ value, setValue }) {
  const { t: translate } = useTranslation();
  return (
    <div
      aria-hidden="true"
      className={styles.show_more_less}
      onClick={() => setValue(!value)}
    >
      {!value ? (
        <span>
          +
          {translate('Show More')}
        </span>
      ) : (
        <span>
          {' '}
          -
          {translate('Show Less')}
        </span>
      )}
    </div>
  );
}

export default ShowMoreLess;
