export default function GridView({
  className,
  columnsCount = 2,
  gap = '0.5rem',
  children,
}) {
  const totalGap = parseFloat(gap) * (columnsCount - 1);

  const gridStyles = {
    display: 'grid',
    gridTemplateColumns: `repeat(${columnsCount}, calc(${100 / columnsCount}% - (${totalGap}rem / ${columnsCount})))`,
    gridGap: gap,
  };

  return (
    <div className={className} style={gridStyles}>
      {children}
    </div>
  );
}
