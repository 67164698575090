// Core Packages
import { useMemo, useState } from 'react';
import classnames from 'classnames';

// Material
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import debounce from '@material-ui/core/utils/debounce';

// Styles
import styles from './style.module.css';

export default function SearchField({
  className,
  placeholder = 'Search',
  onChange,
  useDebouncing = true,
  setShowMore = () => {},
  hideCrossButton = false,
}) {
  const searchbarClasses = classnames(styles.searchBar, className);
  const [searchValue, setSearchValue] = useState('');
  const _debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const onSearchUpdate = (event) => {
    const { value = '' } = event.target;
    setSearchValue(value);
    _debouncedOnChange(value);
    if (useDebouncing) {
      _debouncedOnChange(value);
    } else {
      onChange(value);
    }
    setShowMore(true);
  };

  const onSearchCancel = () => {
    setSearchValue('');
    onChange('');
    setShowMore(false);
  };

  return (
    <InputBase
      data-test="component-search-bar"
      value={searchValue}
      className={searchbarClasses}
      placeholder={placeholder}
      startAdornment={(
        <InputAdornment position="start">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.43391 2.0625C11.3889 2.0625 13.2639 2.84748 14.6463 4.24476C16.0287 5.64205 16.8053 7.53716 16.8053 9.51322C16.8053 13.6281 13.505 16.9639 9.43391 16.9639C5.36279 16.9639 2.0625 13.6281 2.0625 9.51322C2.0625 5.3983 5.36279 2.0625 9.43391 2.0625Z"
              fill="#8991AC"
            />
            <path
              d="M16.6831 15.5154C16.351 15.1855 15.8185 15.1855 15.4863 15.5154C15.3141 15.6889 15.2173 15.9246 15.2173 16.1703C15.2173 16.381 15.2884 16.5842 15.4172 16.7473L15.4863 16.8253L17.3082 18.9133C17.7524 19.3622 18.4725 19.3622 18.9167 18.9133C19.3608 18.4644 19.3608 17.7365 18.9167 17.2876H18.8786L16.6831 15.5154Z"
              fill="#D2D9E3"
            />
          </svg>
        </InputAdornment>
      )}
      endAdornment={
        searchValue
        && !hideCrossButton && (
          <InputAdornment
            className={styles.clearBtn}
            onClick={onSearchCancel}
            position="end"
          >
            <svg
              className={styles.icon}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.16683 5.34199L5.34183 4.16699L10.0002 8.82533L14.6585 4.16699L15.8335 5.34199L11.1752 10.0003L15.8335 14.6587L14.6585 15.8337L10.0002 11.1753L5.34183 15.8337L4.16683 14.6587L8.82516 10.0003L4.16683 5.34199Z"
                fill="#69778C"
              />
            </svg>
          </InputAdornment>
        )
      }
      onInput={onSearchUpdate}
    />
  );
}
