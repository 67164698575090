import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function useUrlSync({
  sort,
}) {
  const router = useRouter();

  useEffect(() => {
    router.replace({ pathname: router.pathname, query: { ...router.query, sort } });
  }, [sort]);
}
