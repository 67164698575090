// core
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '@/contexts/Notification';

// Material
import TablePagination from '@material-ui/core/TablePagination';

// Styles
import styles from './style.module.css';

export default function Pagination({
  size,
  onSizeChange,
  page,
  totalItems,
  disabled = false,
  onChange,
}) {
  const { t: translate } = useTranslation();
  const { showMiniNotification } = useContext(NotificationContext);
  const [typedQuantity, setTypedQuantity] = useState(page || '');
  const paginationClasses = classnames(styles.pagination, 'custom-pagination');
  const pageInputContainerClasses = classnames(
    styles.pageInputContainer,
    'row-start-center',
  );
  const maxPageNumber = Math.ceil(totalItems / size);

  const onPageChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    onChange(value + 1);
    setTypedQuantity(value + 1);
  };
  const onRowsPerPageChange = (event) => {
    onChange(1);
    setTypedQuantity(1);
    onSizeChange(event.target.value);
  };

  const handleQtyError = () => {
    showMiniNotification({
      duration: 3000,
      text: translate(
        maxPageNumber > 1
          ? 'Enter value between 1 and {quantity}'
          : 'You are on page 1 of 1',
      ).replace('{quantity}', maxPageNumber),
      variant: 'topCenter',
    });
  };

  const handleTypedQuantityChange = (val) => {
    if (!val) {
      setTypedQuantity('');
      return;
    }
    const newValue = Number(val);
    const qtyAcceptable = newValue > 0 && newValue <= maxPageNumber;
    console.log(newValue);
    if (qtyAcceptable) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setTypedQuantity(newValue);
      onChange(newValue);
    } else if (newValue === 0) {
      setTypedQuantity('1');
      onChange('1');
    } else {
      handleQtyError();
    }
  };
  useEffect(() => {
    setTypedQuantity(page);
  }, [page]);
  return (
    <div className={styles.paginationContainer}>
      <TablePagination
        data-test="component-pagination"
        className={paginationClasses}
        color="primary"
        disabled={disabled}
        rowsPerPageOptions={[]}
        component="div"
        count={totalItems || 0}
        rowsPerPage={size}
        page={page - 1}
        onChangePage={onPageChange}
        onChangeRowsPerPage={onRowsPerPageChange}
        labelDisplayedRows={() => `Page ${page} of ${maxPageNumber}`}
      />
      <div className={pageInputContainerClasses}>
        <span className={styles.pageLabel}>The page you’re on</span>
        <input
          className={styles.pageInput}
          min={1}
          max={maxPageNumber}
          value={typedQuantity}
          type="number"
          onChange={(e) => handleTypedQuantityChange(e.target.value)}
        />
      </div>
    </div>
  );
}
